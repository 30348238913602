/**
 * 德语
 */
 export default{
    header:{
        Popular:'Beliebt',
        collections:'Neuste Sammlung',
        store:'Laden',
        about:'Über TOKIT',
        account:'Mein Konto',
        collection:'Meine Sammlung',
        device:'Mein Gerät',
        signIn:'Anmelden',
        loginOut:'Abmelden'
    },
    fotter:{
        privacy:'Datenschutzpolitik',
        terms:'Allgemeine Geschäftsbedingungen',
        reserved:'Urheberrecht: tokit2020'
    },
    home:{
        featuredrecipes:'Menü',
        more:'Mehr erfahren',
        collections:'Beliebte Sammlungen',
        delicacy:'Spezialität',
        collection:'Tägliche Sammlung',
        latestRecipes:'Neueste Rezepte',
        cookbook:'Suchen',
        search:'Suchen',
        link:'Links',
        getTheDelicacies: 'Holen Sie sich gutes Essen.',
        gainSomeInspirations: "Lassen Sie sich inspirieren."
    },
    recipesearch:{
        noresult:'Für die ausgewählte Suche wurden keine Ergebnisse gefunden.',
        viewMore:'Mehr sehen',
        collection:'Die Sammlung wurde erfolgreich aktualisiert!',
        recipe:'Das Rezept wurde aus der Sammlung entfernt!',
        noMore: 'Keine daten mehr',
        newest: 'Neueste',
        shortest: 'Gesamtzeit, kürzeste',
        recipes: 'Rezepte',
        collections: 'Sammlungen',
        tags: 'Stichworte',  
        products: 'Produkt',
        category: 'Kategorie',
        showResults: 'Zeige ergebnisse',
        Filters: 'Filter', 
    },
    specialTopicList:{
        explore:'Entdecken Sie unsere neueste Serien.',
        viewAll: 'Alle ansehen',
        Recipes:'Rezepte'
    },
    login:{
        logs:'Melden Sie sich an.',
        email:'E-Mail-Addresse',
        psd:'Passwort',
        by:'Indem Sie sich anmelden, stimmen Sie Folgendem zu:',
        useragreement:'Nutzungsbedingungen',
        and:'und',
        login:'Anmeldung',
        or:'oder',
        createaccount:'Konto erstellen',
        forgotpassword:'Passwort vergessen',
        reserved:'© 2021, (Chunmi Technology (Hong Kong) Limited), alle Rechte vorbehalten.',
        agree:'Stimme zu.',
        privacy:'Bitte lesen Sie unsere Richtlinie und stimmen Sie ihr zu.',
        enterpass:'Geben Sie Ihre E-Mail-Adresse ein, um Anweisungen zum Zurücksetzen Ihres Passworts zu erhalten.',
        exist:'Das Konto existiert nicht.',
        later:'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut.',
        reset:'Zurücksetzen',
        return:'Oder zurück zu',
        denglu:'Anmeldung',
        resetpsd:'Passwort zurücksetzen',
        should:'Sie sollten innerhalb weniger Minuten einen Link erhalten. Bitte öffnen Sie diesen Link, um Ihr Passwort zurückzusetzen.',
        backIn:'Zurück zur Anmeldung',
        setpad:'Passwort einstellen',
        minimum:'Mindestens 8 Zeichen, ein Großbuchstabe, ein Kleinbuchstabe und eine Zahl',
        confirm:'Bestätigen',
        confirmpsd:'Passwort bestätigen',
        passwordMismatch:'Die zweimal eingegebenen Passwörter stimmen nicht überein.',
        incorrect :'Falsches Kennwortformat',
        valids:'Bitte geben Sie ein gültiges Passwort ein.',
        setName:'Stellen Sie Ihren Benutzernamen ein.',
        tip:'Benutzername muss eindeutig sein und darf keine Sonderzeichen enthalten, verwenden Sie bitte Deutsch und Englisch.',
        wrong:'Etwas ist schief gelaufen, bitte versuchen Sie es später erneut.',
        usName:'Benutzername ist bereits in Benutzung.',
        save:'Speichern',
        validmail:'Bitte geben Sie eine gültige E-Mail Adresse an.',
        validpassword:'Bitte geben Sie ein gültiges Passwort ein.',
        validname:'Bitte geben Sie einen gültigen Namen an',
        loginReminder:'Anmeldung-Erinnerung',
        format:'Bitte verwenden Sie das gewünschte Format.'
    },
    recipeDetails:{
        difficulty:'Schwierigkeitsgrad',
        serves:'Sercive',
        preparationtime:'Vorbereitungszeit',
        totaltime:'Gesamtzeit',
        ingredients:'Material',
        preparation:'Vorbereiten',
        relatedrecipes:'Verwandte Rezepte',
        min:'Punkte',
        reterecipe:'Bewerten Sie dieses Rezept.',
        evaluationTips:'Von 1 Stern (sehr schlecht) bis 5 Sterne (sehr gut), wie würdest du dieses Rezept bewerten?',
        angry:'wütend',
        disappointed:'enttäuscht',
        average:'Mittelmäßig',
        satisfied:'zufrieden',
        vsatisfied:'Sehr zufrieden',
        clear:'Abschaffen',
        saveRecipes: 'Rezepte speichern',
        removeRecipes: 'Löscht das rezept.',
        supportedProducts: 'Kompatible Produkte',
        tags: 'Stichworte',
        tips:'Tipps',
        edit: 'Bearbeiten',
        Duration:'Dauer',
        Temperature:'Temperatur',
        Speed:'Geschwindigkeit',
        corotation:'',
        reversal:'-',
        minutes:'Minuten',
        easy:'Einfach',
        Medium:'Medium',
        Difficult:'Schwierig',
        title:'Rezeptdetails',
        positive:'Positive speed',
        reverse:'Reverse speed',
        needHour:'Stunden',
        needMin:'Minuten',
    },
    account:{
        hi:'Hallo,',
        profile:'Mein Profil',
        collection:'Meine Sammlung',
        device:'Mein Gerät',
        editprofile:'Bearbeitung der Konfigurationsdatei',
        firstname:'Name',
        lastname:'Familienname',
        gender:'Geschlecht',
        birthday:'Geburtstagsdatum',
        country:'Land/Region',
        email:'E-Mail',
        phonenum:'Telefonnummer',
        bundle:'Binden Sie Ihr TOKIT-Gerät, um besseren Service zu genießen.',
        addvice:'Gerät hinzufügen',
        bingdevice:'Gerät binden',
        product:'Produkt',
        blank:'Produkt darf nicht leer sein.',
        deviceNo:'Gerät-ID',
        modelBlank:'Modell darf nicht leer sein.',
        submit:'Vorlegen',
        guide:'Anleitung zur Abfrage der Gerät-ID',
        content:'Öffnen Sie das Inhaltsmenü, klicken Sie auf Einstellungen, und dann klicken Sie auf „Info“, um die Gerät-ID zu finden. Mehr erfahren.',
        fullTxt:'Bindungsprozess abschlossen',
        adddevice:'Mein Gerät hinzufügen',
        choose:'Bitte wählen Sie Ihr Produkt aus.',
        modelNum:'Modell',
        personal:'Personeninformationen',
        male:'männlich',
        female:'weiblich',
        country:'Land/Region',
        year:'Jahr',
        month:'Monat',
        day:'Tag',
        complete:'Schließen Sie die Bindung auf Ihrem Gerät ab.',
        completed:'Abgeschlossen',
        noDevice:'Keine Popups auf meinem Gerät',
        tryAlign:'Versuchen Sie es nochmal.',
        removeCollection:'Sammlung löschen',
        doreally:'Möchten Sie diese Sammlung wirklich löschen?',
        firstRecipe:'Speichern Sie Ihr erstes Rezept!',
        theSave:'Klicken Sie auf Speichern.',
        icon:'Speichern Sie das Symbol für die spätere Verwendung auf einem Rezept.',
        findRecipe:'Rezept finden',
        successfully:'Bindung erfolgreich!',
        removedevice:'Das Gerät wurde erfolgreich entfernt.',
        bindingFailed:'Bindung fehlgeschlagen',
        removeTOKIT: 'TOTKIT entfernen',
        removeTips: 'Möchten Sie Ihr TOKIT wirklich deaktivieren?',
        cancel: 'STORNIEREN',
        remove: 'ENTFERNEN',
		loadTip:'Laden Sie die APP herunter, um Geräte zu binden'
    },
    aboutus:{
        seek:'Was suchen wir?',
        helping:'Hilft beim Kochen',
        journey:'Kochen kann jetzt eine einfache und angenehme Reise sein.',
        providing:'Bietet intelligente Kochlösungen an.',
        continuing:'Erforscht und entwickelt weiter das intelligenteste Küchenerlebnis.',
        database:'Cloudbasierte Kochdatenbank',
        building:'Erstellt ein umfangreicheres und einfallsreicheres Menü für Benutzer.',
        history:'Geschichte',
        previous:'Vorherige',
        next:'Nächste',
        ourJourney:'Unsere Reise beginnt im Mai',
        established:"TOKIT Technology wurde im Mai 2013 gegründet, startete Spark und engagiert sich für die Entwicklung und Anwendung von Internet of Things und Cloud-Appliances.",
        ourfirst:'Unser erstes Produkt',
        intend:'Um Küchenutensilien gemäß den technologischen Anforderungen elektronischer Produkte neu zu definieren, fangen wir mit Verbesserung und Reformation beim Reiskocher an.',
        awards:'Unsere Auszeichnungen seit 2020',
        since:'Seit unserer Gründung wurden wir von vielen Organisationen anerkannt und erhielten 48 Auszeichnungen von IF, Red Dot, G-Mark, IDEA und mehr.',
        launched:'Einführung des TOKIT-Prototyps',
        developing:'Wir haben im Laufe des Jahres 2020 TOKIT-Prototypen entwickelt, die als T0, T1 und T2 aufgeführt.',
        Kickstarter:'$1142227 auf Kickstarter für den Sommer',
        reached:'Mit der offiziellen weltweiten Einführung unseres TOKIT Omni Cook haben wir 1566 Unterstützer auf Kickerstarter und einen Umsatz von $1142227.',
        website:'website And Cooknjoy, kommenden September',
        distribution:'Wir haben im September 2021 die globale offizielle Website und die Cooknjoy-Plattform gestartet, um den weltweiten Vertrieb von TOKIT vorzubereiten.',
        Soon:'Joyami kommt bald.',
        cooker:'Ende 2021 werden wir unsere TOKIT-Untermarke joyami auf den Markt bringen. Unten ist eine Vorschau von joyami für unseren neuen Reiskocher.',
        define:'Kochverhalten mit Temperatur definieren',
        creating:'Zuverlässige Technologie zur Bereicherung des Lebens',
        staffed:'Unser Team besteht aus 300 Eliten aus der globalen Technologiegiganten Apple, IBM, Philips und vielen anderen Unternehmen; Team D macht 65 % aus. Wir haben bisher mehr als 800 Patente und sind mit Internet-Genetik und fortschrittlicher Herstellungstechnologie ausgestattet.'
    }
}