import api from '@/api/api';
import {setToken, getToken,getUserName,setUserName} from '@/libs/util'
import {Notification} from 'element-ui'


const user = {
    state: {
        email:'',
        password:'',
        username: '',
        avatorImgPath: '',
        token: getToken(),
        access: '',
    },
    mutations: {
        setAvator(state, avatorPath) {
            state.avatorImgPath = avatorPath
        },
        setEmail(state, email) {
            state.email = email
        },
        setUserName(state, name) {
            state.username = name
            setUserName(name)
        },
        setAccess(state, access) {
            state.access = access
        },
        setToken(state, token) {
            state.token = token
            setToken(token)
        }
    },
    actions: {
        // 登录
        handleLogin({commit}, {email, password,loginTips}) {
            email = email
            return new Promise((resolve, reject) => {
                api.login({
                    email: email,
                    password: password
                }).then(res => {
                    if (res.code === 200) {
                        commit('setToken', res.result)
                        resolve()
                    } else {
                        Notification.error({
                            title: loginTips, // '登录提醒',
                            message: res.message
                        })
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        },
        // 退出登录
        handleLogOut({state, commit}) {
            return new Promise((resolve, reject) => {
                // api.logout(state.token).then(() => {
                //     commit('setToken', '')
                //     commit('setAccess', [])
                //     resolve()
                // }).catch(err => {
                //     reject(err)
                // })
                // 如果你的退出登录无需请求接口，则可以直接使用下面三行代码而无需使用logout调用接口
                commit('setToken', '')
                commit('setAccess', [])
                resolve()
            })
        },
        // 获取用户相关信息
        getSysAdminInfo({state, commit}) {
            return new Promise((resolve, reject) => {
                // if (state.token != undefined && state.token != '' && state.token != null) {
                //     api.getSysAdminInfo(state.token).then(res => {
                //         if (res.code === 200) {
                //             commit('setUserName', res.result.username)
                //             commit('setUserId', res.result.id)
                //             commit('setAvator', res.result.headPic)
                //         }
                //         resolve(res)
                //     }).catch(err => {
                //         reject(err)
                //     })
                // }

            })
        }
    }
};

export default user;
