const getters = {
    username: state => state.username,//用户名称
    email: state => state.email,//用户邮箱

    sidebar: state => state.app.sidebar,
    visitedViews: state => state.app.visitedViews, //快速导航tab
    permission_routers: state => {
        // 对应permission.js中的state.routers

        return state.permission.routers;

    },
    addRouters: state => state.permission.addRouters
};
export default getters
