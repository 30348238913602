/**
 * 法语
 */
 export default{
    header:{
        Popular:'Favoris',
        collections:'Les ensembles les plus récents',
        store:'Magasin',
        about:'À propos de TOKIT',
        account:'Mon Compte',
        collection:'Mes favoris',
        device:'Mon dispositif',
        signIn:'Connexion',
        loginOut:'Annuler'
    },
    fotter:{
        privacy:'Politique de confidentialité',
        terms:'Clauses de Services',
        reserved:'Droits réservés de tokit2020'
    },
    home:{
        featuredrecipes:'Menu',
        more:'En savoir plus',
        collections:'Les ensembles les plus cherchés',
        delicacy:'Gastronomie spéciale',
        collection:'Favoris par jour',
        latestRecipes:'Nouvelles recettes',
        cookbook:'Recherche',
        search:'Rechercher',
        link:'Rapide liaison',
        getTheDelicacies: 'Accéder à plus de recettes',
        gainSomeInspirations: "Plus d'inspiration"
    },
    recipesearch:{
        noresult:'Aucun résultat trouvé',
        viewMore:'Voir plus',
        collection:'Ensembles mis à jour',
        recipe:'Recettes supprimées dans les favoris',
        noMore: 'Plus de données pour le moment!',
        newest: 'Dernière mise à jour',
        shortest: 'Temps total, minimum',
        recipes: 'Recettes',
        collections: 'Collections',
        tags: 'Mots clés',  
        products: 'Produit',
        category: 'Catégorie',
        showResults: 'Montrer les résultats',
        Filters: 'Filtres', 
    },
    specialTopicList:{
        explore:'Découvrir nos séries les plus récentes ',
        viewAll:'Consulter tout',
        Recipes:'recettes'
    },
    login:{
        logs:'Connectez-vous à votre compte',
        email:'Adresse email',
        psd:'MDP',
        by:'Vous êtes d’accord en vous-connectant à votre compte:',
        useragreement:'Protocole d’utilisateur',
        and:'Et',
        login:'Connexion',
        or:'Ou',
        createaccount:'Créer un nouveau compte',
        forgotpassword:'Oublier le MDP',
        reserved:'©2021，Chunmi Technology (Hong Kong) Limited Droits réservés.',
        agree:'J’accepte ',
        privacy:'Veuillez lire et accepter nos politiques !',
        enterpass:'Enregistrer votre email pour recevoir les instructions pour initialiser le mot de passe',
        exist:'Le compte  n’existe pas',
        later:'Ops, défaillance ! Réessayer plus tard',
        reset:'Réinitialiser',
        return:'OU Retourner à ',
        denglu:'Connexion',
        resetpsd:'Réinitialiser le mot de passe',
        should:'Vous recevrez une liaison dans quelques minutes Veuillez ouvrir le lien pour réinitialiser votre mot de passe.',
        backIn:'Retourner pour vous connecter',
        setpad:'Régler le MDP',
        minimum:'Au moins 8 caractères, au moins une lettre majuscule, une lettre minuscule et un chiffre',
        confirm:'Confirmer',
        confirmpsd:'Confirmer le MDP',
        passwordMismatch:'Le mot de passe ne correspond pas',
        incorrect :'Le format du mot de passe incorrect',
        valids:'Veuillez enregistrer le mot de passe valide ',
        setName:'Enregistrer votre nom du client',
        tip:'Le surnom doit être unique, en chinois et en anglais, ne contenant pas de caractères spéciaux',
        wrong:'Ops, défaillance ! Réessayer plus tard',
        usName:'Le nom du client existe',
        save:'Enregistrer',
        validmail:'Enregistrer l’adresse email correct',
        validpassword:'Veuillez enregistrer le mot de passe valide',
        validname:'Enregistrer le nom correct',
        loginReminder:'Rappel de connexion',
        format:'Veuillez correspondre au format requis'
    },
    recipeDetails:{
        difficulty:'Difficulté',
        serves:'Services',
        preparationtime:'Temps de préparation',
        totaltime:'Temps total',
        ingredients:'Composition',
        preparation:'Préparation',
        relatedrecipes:'Recettes relatives',
        min:'Minute',
        reterecipe:'Évaluer cette recette',
        evaluationTips:'Comment vous évaluez cette recette ? De 1 star à 5 stars',
        angry:'Fâché',
        disappointed:'Déçu',
        average:'Pas mal',
        satisfied:'Satisfait',
        vsatisfied:'Très satisfait',
        clear:'Annuler',
        saveRecipes: 'Sauvegardez la recette',
        removeRecipes: 'Supprimer une recette',
        supportedProducts: 'Produits compatibles',
        tags: 'Mots clés',
        tips:'Conseils',
        edit: 'Modifier',
        Duration:'Durée',
        Temperature:'Température',
        Speed:'Vitesse',
        corotation:'',
        reversal:'-',
        minutes:'minutes',
        easy:'Facile',
        Medium:'Moyen',
        Difficult:'Difficile',
        title:'Détails de la recette',
        positive:'Vitesse positive',
        reverse:'Vitesse de marche arrière',
        needHour:'heures',
        needMin:'minutes',
    },
    account:{
        hi:'Salut ',
        profile:'Informations individuelles',
        collection:'Mes favoris',
        device:'Mon dispositif ',
        editprofile:'Éditer les dossiers de configuration',
        firstname:'Prénom',
        lastname:'Nom',
        gender:'Sexe',
        birthday:'Date de naissance',
        country:'Pays/région',
        email:'email',
        phonenum:'Tél.:',
        bundle: "Lier avec votre équipement TOKIT et profit d'un meilleur service",
        addvice:'Ajouter un nouvel appareil',
        bingdevice:'Lier votre appareil',
        product:'Produits',
        blank:'Le produit est obligatoire.',
        deviceNo:'Numéro d’équipement',
        modelBlank:'Le modèle est obligatoire',
        submit:'Soumettre',
        guide:'Comment consulter le numéro d’équipement',
        content:"Ouvrir le menu Contenu, cliquer sur Paramètres Aller à la page, puis cliquez sur « À propos » pour accéder à la page pour trouver les appareils dont le modèle n'est pas affiché. En savoir plus",
        fullTxt:'Processus de liaison',
        adddevice:'Ajouter mon appareil',
        choose:'Choisir votre produit',
        modelNum:'Modèle',
        personal:'Informations personnelles',
        male:'Masculin',
        female:'Féminin',
        country:'Pays/région',
        year:'Année',
        month:'Mois',
        day:'Jour(s)',
        complete:'Veuillez achever la liaison sur votre appareil.',
        completed:'Terminer',
        noDevice:'Aucun pop-up apparu sur mon appareil',
        tryAlign:'Essayer encore.',
        removeCollection:'Supprimer l’ensemble',
        doreally:'Confirmer de supprimer l’ensemble ?',
        firstRecipe:'Conserver votre première recette !',
        theSave:'Cliquer pour sauver',
        icon:'Sauver l’icône au-dessus de la recette pour l’utiliser au futur',
        findRecipe:'Chercher la recette ',
        successfully:'Liaison achevée !',
        removedevice:'Appareil déjà supprimé',
        bindingFailed:'Impossible de lier',
        removeTOKIT: 'Supprimer totkit',
        removeTips: 'Voulez - vous vraiment désactiver votre tokit?',
        cancel: 'Annulation',
        remove: 'Supprimer',
		loadTip:"Téléchargez l'application pour lier les appareils"
    },
    aboutus:{
        seek:'Ce que nous cherchons',
        helping:'Simplifier la cuisine',
        journey:'La cuisine est un séjour agréable',
        providing:'Solutions de cuisson intelligentes',
        continuing:'Poursuivre la recherche et le développement des expériences de cuisine les plus intelligentes.',
        database:'Banque de données de cuisson basée sur le cloud',
        building:'Créer un menu plus étendu et imaginatif pour les utilisateurs.',
        history:'Histoire',
        previous:'Précédent',
        next:'Suivant',
        ourJourney:'Notre voyage a commencé en mai',
        established:"TOKIT Technology, fondée en mai 2013, a lancé le spark et a commencé à travailler sur le développement et l'application de l'Internet des objets et des appareils  électroménagers en nuage. ",
        ourfirst:'Notre premier produit lancé',
        intend:'Pour redéfinir les ustensiles de cuisine en fonction des exigences techniques des produits électroniques, nous innovons avec des cuiseurs à riz électriques pour les mettre à jour et réformer. ',
        awards:'Nos récompenses depuis 2020',
        since:"Depuis la création de la marque, nous avons été reconnus par de nombreuses organisations et nous avons reçu 48 prix de la part de IF, Red Dot, G-Mark, IDEA et d’autres institutions. ",
        launched:'Lancement de modèle d’origine de TOKIT',
        developing:'Nous avons développé les modèles de TOKIT en 2020, intitulés respectivement T0, T1 et T2. ',
        Kickstarter:'$1142227 sur Kickstartor en été',
        reached:'Avec le lancement officiel de notre TOKIT Omni Cook dans le monde entier, 1,566 acheteurs sur kickerstarter, les ventes atteignent $1142 227',
        website:'website And Cooknjoy, Septembre à venir',
        distribution:'Nous avons lancé le site officiel mondial et la plateforme Cooknjoy en septembre 2021 pour la distribution mondiale de TOKIT.',
        Soon:'Joyami vient d’apparaître',
        cooker:'La nouvelle marque de TOKIT, joyami voit le jour en fin de l’année 2021 Voilà un aperçu de joyami pour notre nouveau cuiseur à riz.',
        define:'Définir l’attitude de cuisine par température',
        creating:'Une technologie qui vise à enrichir votre vie',
        staffed:"Composé de 300 élites des géants mondiaux de la technologie Apple, IBM, Philips et de nombreuses autres sociétés; L'équipe D représente 65%. Jusqu’à présent, nous avons plus de 800 propriétés intellectuelles et adoptons la génétique Internet et des technologies de fabrication avancées."
    }
}