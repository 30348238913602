const getRegion = ()=>{
    // 大陆:cn 欧洲:eu 北美:us 新加坡:ap 俄罗斯:ru
    let href = location.href
    if(href.indexOf('/na') !== -1){
        return 'us'
    }else if(href.indexOf('/eu') !== -1){
        return 'eu'
    }else if(href.indexOf('/ru') !== -1){
        return 'ru'
    }else{
        return 'ap'
    }
}

// 系统判断
const detectMobileDevice = ()=>{
    console.log('******')
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // iOS检测
    if (/iPhone|iPad|iPod/.test(userAgent)) {
        return 'ios'
    }

    // 安卓检测
    if (/android/i.test(userAgent)) {
        return 'Android'
    }
}

// 从tab页跳到非tab页
const pushAppWebView = (dataSource)=>{
    const platform = detectMobileDevice()
    if (platform === 'ios') {
        window.webkit.messageHandlers.pushAppWebView.postMessage(dataSource)
    } else {
        window.android && window.android.pushAppWebView && window.android.pushAppWebView(dataSource)
    }
}

// 食谱详情页编辑事件
const recipeEditClick = (recipeData)=>{
    const platform = detectMobileDevice()
    if (platform === 'ios') {
        window.webkit.messageHandlers.recipeEditClick.postMessage(recipeData);
    } else {
        window.android && window.android.recipeEditClick && window.android.recipeEditClick(recipeData);
    }
}

// 首页导航栏＋号事件
const navigationAddClick = ()=>{
    const platform = detectMobileDevice()
    if (platform === 'ios') {
        window.webkit.messageHandlers.navigationAddClick.postMessage('');
    } else {
        window.android && window.android.navigationAddClick && window.android.navigationAddClick();
    }
}

// 返回
const appBack = ()=>{
    const platform = detectMobileDevice()
    if (platform === 'ios') {
        window.webkit.messageHandlers.appBack.postMessage('');
    } else {
        window.android && window.android.appBack && window.android.appBack();
    }
}



export default {
    getRegion,
    detectMobileDevice,
    pushAppWebView,
    recipeEditClick,
    navigationAddClick,
    appBack
}