//七牛上传图片前缀
const qiniuImgBaseUrl = "https://img.joyami.com/"

//七牛上传视频前缀
const qiniuVideoBaseUrl = "https://video.joyami.com/"

//七牛上传路径（华东服务器）
const qiniuUploadUrl_z0 = "https://upload.qiniup.com"

const baseUrl = "https://c2-console.joyami.com" //https://c2-console.joyami.com  

export default {
    baseUrl
}

