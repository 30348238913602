import axios from 'axios'
import {generateNonce, generateSignature, generateSignature_v2, getUserName} from "../libs/util";

var ui = require('element-ui')
// 判断是否为欧洲地区
console.log(window.location.origin)
 export var eu = (window.location.origin.indexOf('eu')>-1)?true:false

// 判断是否为俄罗斯地区
export const ru = (window.location.origin.indexOf('ru')>-1)?true:false

axios.defaults.timeout = 1800000;
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

//拦截器
axios.interceptors.request.use((config) => {

    let url = config.url.replace("api", "");
    if (url.indexOf("?") > 0) {
        url = url.substring(url.indexOf("?"), -1);
    }
    let method = config.method.toUpperCase();

console.log(url,'service---')
    const appId = '202007190935013' 
    if(url.startsWith('/user-')){
        // 添加请求签名
        config.headers.signature = generateSignature(appId, method, url);
        console.log('heiheieh')
    }else{
        const timestamp = new Date().getTime()
        const nonce = generateNonce(timestamp)
        const signature = generateSignature_v2(appId, timestamp, nonce, method, url)
        config.headers.signature = signature
        config.headers.nonce = nonce
        config.headers.timestamp = timestamp

        // 如果是创作详情接口，需要修改签名方式（因为此接口是调用海外tkapp的接口）
        if(url.startsWith('/creation-')){
            const sign = generateSignature(appId, method.toUpperCase(), url.split("?")[0]);
            config.headers.signature = sign
            console.log('sahshasah')
        }
    }
   
   

    config.headers.appId = appId

    let userName = getUserName()

    if (userName) {
        config.headers.UserName = userName
        return config
    }
    if (config.method === 'post') {
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use((res) => {
    return res;
}, (error) => {
    return Promise.reject(error);
});

export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params).then(response => {
            if (response.data.code == 403) {
                ui.Message({
                    message: '签名验证失败',
                    type: 'warning'
                })
            }
            resolve(response.data);
        }, err => {
            reject(err);
        }).catch((error) => {
            reject(error)
        })
    })
}

export function get(url, params) {
    if (params !== null && params !== '' && params !== undefined) {

        let dataStr = ''
        Object.keys(params).forEach(key => {
            if (params[key] !== undefined) {
                dataStr += key + '=' + params[key] + '&'
            }

        })

        if (dataStr !== '') {
            dataStr = dataStr.substr(0, dataStr.lastIndexOf('&'))
            url = url + '?' + dataStr
        }

    }
    return new Promise((resolve, reject) => {
        axios.get(url)
            .then(response => {
                if (response.data.code == 403) {
                    ui.Message({
                        message: response.data.msg || '签名验证失败',
                        type: 'warning'
                    })
                }
                resolve(response.data);
            }, err => {
                reject(err);
            })
            .catch((error) => {
                reject(error)
            })
    })

}

export default {
    getMainStationHomePage(params) {
        return get('api/c-recipe-service/rpc/tagClassify/getMainStationHomePage', params);   // 首页聚合页接口
    },
    getPlatSearch(params) {
        return post('api/c-recipe-service/rpc/tagClassify/getPlatSearch', params);   // 搜索食谱
    },
    getPlatRecommendRecipeList(params) {
        return get('api/c-recipe-service/rpc/tagClassify/getPlatRecommendRecipeList', params); // 推荐食谱
    },
    allvitamix(params) {
        return get('api/c-homepage-service/api/specialTopic/list/allvitamix', params); // 专题列表
    },
    specialTopicDetail(params) {
        return get(`api/c-homepage-service/api/specialTopic/${params.specialTopicId}/detail/main`, params); // 专题详情
    },
    platCollectList(params) {
        return get('api/c-recipe-service/rpc/recipe/platCollectList', params); // ⻝谱收藏列表
    },
    platCollectOrCancel(params) {
        return post('api/c-recipe-service/rpc/recipe/platCollectOrCancel', params); // ⻝谱收藏或取消收藏
    },
    getCollectMap(params) {
        return post('api/c-recipe-service/rpc/recipe/getCollectMap', params); // 查询⽤户是否收藏⻝谱
    },
    getCreationInfo(params) {
        return get(`api/creation-service/api/creation/info`, params); // 创作详情(海外TOKIT APP独有)
    },
    getrecipeInfo(params) {
        return get(`api/c-recipe-service/api/recipe/${params.recipeId}/info`, params); // ⻝谱详细信息(含⻝材及步骤)
    },
    platProductList(params){
        return get('api/c-recipe-service/api/product/platProductList',params); // 产品列表
    },
    getDeviceByUserId(params){
        return get('api/c-device-service/rpc/device/getDeviceByUserId', params); // 用户设备列表
    },
    platDeviceRegister(params){
        return post('api/c-device-service/rpc/device/platDeviceRegister', params); //主站设备发起绑定
    },
    platDeviceUnbind(params){
        return post('api/c-device-service/rpc/device/platDeviceUnbind', params); // 设备解绑接口
    },
    platDeviceBindState(params){
        return post('api/c-device-service/rpc/device/platDeviceBindState', params); // 绑定设备状态查询
    },
    register(params){
        return post('api/user-service-overseas/api/user/overseas/register', params); // 用户注册
    },
    login(params){
        return post('api/user-service-overseas/api/user/overseas/login', params); // 用户登录
    },
    forgetPassword(params){
        return post('api/user-service-overseas/api/user/overseas/forgetPassword', params); // 忘记密码，发送邮件
    },
    getPassResetPwd(params){
        return post('api/user-service-overseas/api/user/overseas/getPassResetPwd', params); // 忘记密码，链接点击
    },
    passwordReset(params){
        return post('api/user-service-overseas/api/user/overseas/passwordReset', params); // 忘记密码，密码重设
    },
    update(params){
        return post('api/user-service-overseas/api/user/overseas/update', params); // 修改个人信息
    },
    getInfo(params){
        return post('api/user-service-overseas/api/user/overseas/info', params); // 查询个人信息
    },
    setScore(params){
        return post('api/c-recipe-service/rpc/recipe/score', params); // 食谱评分
    },
    getProductTagClassify(params){
        return get('api/c-recipe-service/rpc/tagClassify/getProductTagClassify', params)
    }
    
    
}
