/**
 * 日语
 */
 export default{
    header:{
        Popular:'人気',
        collections:'最新の集合',
        store:'商店',
        about:'TOKITについて',
        account:'マイアカウント',
        collection:'マイコレクション',
        device:'マイデバイス',
        signIn:'ログイン',
        loginOut:'ログアウト'
    },
    fotter:{
        privacy:'プライバシーポリシー',
        terms:'サービス条件',
        reserved:'著作権所有：tokit 2020'
    },
    home:{
        featuredrecipes:'メニュー',
        more:'もっと知りたい',
        collections:'人気の集まり',
        delicacy:'特色ある美食',
        collection:'デイリーコレクション',
        latestRecipes:'最新レシピ',
        cookbook:'検索',
        search:'検索',
        link:'ラピッドリンク',
        getTheDelicacies: '美食を得ます',
        gainSomeInspirations: "インスピレーションを得ます"
    },
    recipesearch:{
        noresult:'選択した検索結果が見つかりませんでした。',
        viewMore:'続きを見る',
        collection:'集合は正常に更新されました！',
        recipe:'レシピはコレクションから削除されました！',
        noMore: 'これ以上のデータはありません!',
        newest: '最新',
        shortest: '総時間、最短',
        recipes: 'レシピ',
        collections: '集合',
        tags: 'ラベル',
        products: '製品',
        category: '分類',
        showResults: '結果を見る',
        Filters: 'フィルター',   
    },
    specialTopicList:{
        explore:'最新シリーズを探します',
        viewAll: 'すべてを見る',
        Recipes:'レシピ'
    },
    login:{
        logs:'アカウントにログイン',
        email:'Eメールアドレス',
        psd:'パスワード',
        by:'ログインにより、次のことに同意します。',
        useragreement:'コンシューマプロトコル',
        and:'と',
        login:'ログイン',
        or:'または',
        createaccount:'アカウントを作成します',
        forgotpassword:'パスワードを忘れました',
        reserved:'©2021、（Chunmi Technology（Hong Kong）Limited）著作権所有。',
        agree:'同意します',
        privacy:'当社の政策をお読みの上、同意してください。',
        enterpass:'パスワードをリセットする方法についての説明を受信するために電子メールを入力してください。',
        exist:'このアカウントは存在していません。',
        later:'問題が発生して、しばらくしてからお試しください。',
        reset:'リセットします',
        return:'または',
        denglu:'ログインに戻ります',
        resetpsd:'パスワードをリセットします',
        should:'数分以内にリンクが届きますので、このリンクを開いてパスワードをリセットしてください。',
        backIn:'ログインに戻ります',
        setpad:'パスワードを設定してくさい',
        minimum:'少なくとも8文字、1つの大文字、1つの小文字と1つの数字',
        confirm:'確認します',
        confirmpsd:'パスワードを確認します',
        passwordMismatch:'2回のパスワードが一致していません',
        incorrect :'パスワードのフォーマットが正しくありません',
        valids:'有効なパスワードを入力してください。',
        setName:'ユーザー名を設定します',
        tip:'ニックネームは唯一で、中国語と英語を使用して、特殊文字を含まりません',
        wrong:'問題が発生して、しばらくしてからお試しください。',
        usName:'ユーザー名が既に使用されています。',
        save:'保存します',
        validmail:'有効な電子メールアドレスを入力してください。',
        validpassword:'有効なパスワードを入力してください。',
        validname:'有効な名前を入力してください',
        loginReminder:'ログインしてください',
        format:'要求のフォーマットに一致してください'
    },
    recipeDetails:{
        difficulty:'難易度',
        serves:'サービス',
        preparationtime:'準備時間',
        totaltime:'合計時間',
        ingredients:'成分',
        preparation:'準備準備',
        relatedrecipes:'関連レシピ',
        min:'点',
        reterecipe:'このレシピを評価します',
        evaluationTips:'1つ星（非常に悪い）から5つ星（非常に良い）まで、このレシピをどのように評価しますか？',
        angry:'腹が立つ',
        disappointed:'がっかり',
        average:'一般',
        satisfied:'満足',
        vsatisfied:'とても満足',
        clear:'キャンセル',
        saveRecipes: 'レシピの保存',
        removeRecipes: 'レシピの削除',
        supportedProducts: '対象製品',
        tags: 'ラベル',
        tips:'チップ',
        edit: '編集',
        Duration:'間隔',
        Temperature:'温度',
        Speed:'スピード',
        corotation:'',
        reversal:'-',
        minutes:'分',
        easy:'簡単',
        Medium:'中くらい',
        Difficult:'もっと難しい',
        title:'レシピ詳細',
        positive:'時計回りに',
        reverse:'反時計回りに',
        needHour:'時間',
        needMin:'分',
    },
    account:{
        hi:'こんにちは、',
        profile:'マイプロフィール',
        collection:'マイコレクション',
        device:'マイデバイス',
        editprofile:'プロファイルを編集します',
        firstname:'名前',
        lastname:'姓',
        gender:'性別',
        birthday:'誕生日',
        country:'国/地域',
        email:'email',
        phonenum:'電話番号',
        bundle:'TOKITデバイスをバインドして、より良いサービスを受けます',
        addvice:'デバイスを追加します',
        bingdevice:'デバイスをバインドします',
        product:'製品',
        blank:'製品を空にできません',
        deviceNo:'デバイス番号',
        modelBlank:'モデルは空にできません',
        submit:'送信します',
        guide:'デバイス番号検索ガイド',
        content:'コンテンツオプションを開き、設定をクリックしてページにジャンプし、「について」をクリックしてページにジャンプし、モデルが表示されていないデバイスを探します。についてをもっとたくさん見ます',
        fullTxt:'完全なバインド手順',
        adddevice:'マイデバイスを追加します',
        choose:'製品を選択してください。',
        modelNum:'モデル',
        personal:'個人情報',
        male:'男性',
        female:'女性',
        country:'国または地域',
        year:'年',
        month:'月',
        day:'日',
        complete:'デバイス上でバインドを完了してください。',
        completed:'完了しました',
        noDevice:'デバイスにはポップアップウィンドウがありません',
        tryAlign:'もう一度試します',
        removeCollection:'集合を削除します',
        doreally:'この集合を削除してもよろしいですか？',
        firstRecipe:'最初のレシピを保存します！',
        theSave:'レシピにあるアイコンの保存をクリックして、',
        icon:'将来の使用に備えて保存します。',
        findRecipe:'レシピを検索します',
        successfully:'バインドに成功しました！',
        removedevice:'デバイスは正常に削除されました。',
        bindingFailed:'バインドに失敗しました',
        removeTOKIT: 'トークンを削除する',
        removeTips: '本当にあなたのトークンを無効にしますか？',
        cancel: '取り消し',
        remove: '取り外し',
		loadTip:'APPをダウンロードしてデバイスをバインドします'
    },
    aboutus:{
        seek:'我々は何を求めて',
        helping:'料理の手伝いをしやすくします',
        journey:'料理は今、気軽な旅になることができます。',
        providing:'インテリジェントな調理ソリューションを提供します',
        continuing:'最もスマートなキッチン体験の研究開発を続けています。',
        database:'クラウドベースの料理データベースに基づいて、',
        building:'ユーザーのためにより拡張性と想像力に富んだオプションを構築します。',
        history:'履歴',
        previous:'前へ',
        next:'次へ',
        ourJourney:'我々の旅は5月から始まっています',
        established:"2013年5月に設立され、TOKIT Technologyはsparkを立ち上げ、モノのインターネットとクラウド家電の開発と応用に力を注いでいます。",
        ourfirst:'当社最初の製品は',
        intend:'電子製品の科学技術的要求に基づいて台所用具を再定義するために、炊飯器からグレードアップと改革を始めました。',
        awards:'我々が2020年以降に受賞した賞について、',
        since:'設立以来、私たちは多くの組織から認可を受け、IF、Red Dot、G-Mark、IDEAなどから48の賞を受賞しました。',
        launched:'TOKITプロトタイプ発射',
        developing:'2020年を通してT 0、T 1、T 2のTOKITプロトタイプを開発しました',
        Kickstarter:'$114,2227夏のKickstartorで',
        reached:'我々のTOKIT Omni Cookが世界で正式に発売されたことに伴い、kickerstarterには1566人の支持者がおり、売上高は114,2227ドルに達しました',
        website:'website And Cooknjoy来る9月に',
        distribution:'2021年9月にグローバル公式サイトとCooknjoyプラットフォームを立ち上げ、TOKITグローバルディストリビューションに備えています。',
        Soon:'joyamiはもうすぐ来ます',
        cooker:'2021年末にTOKITのサブブランドjoyamiを発売します。次はjoyamiが当社の新しい炊飯器に提供してくれたプレビューです。',
        define:'温度で調理態度を定義',
        creating:'豊かな生活を創造する信頼できるテクノロジー',
        staffed:'世界的なテクノロジー大手アップル、IBM、フィリップス、その他の多くの企業から300人のエリートで構成されています。Dチームは65%を占めている。これまで800件以上の特許を保有し、インターネット遺伝学と先進的な製造技術を搭載しています'
    }
}