//google-analyze.js
// 事件跟踪


export default {
    GA_TRACKING_ID: window.localStorage.getItem('gaID'), //ga的id
    event(eventCategory, eventAction, eventLabel, eventValue) {
        if (window.gtag) {
            window.gtag('event', eventAction, {
                event_category: eventCategory,  // 品牌brand、视频video
                event_label: eventLabel,   // 品牌ID、视频title
                eventAction: eventAction, // click、download
                value: eventValue,  // 正整数
                send_to: this.GA_TRACKING_ID   
            })
        }
    },
    page(page, title, location) {
        if (window.gtag) {
            window.gtag('config', this.GA_TRACKING_ID, {
                page_title: title,
                page_path: page,
                page_location: location
            })
        }
    }
}
