/**
 * 意大利
 */
 export default{
    header:{
        Popular:'Popolare',
        collections:'Ultima collezione',
        store:'Negozio',
        about:'Su TOKIT',
        account:'Mio conto',
        collection:'Mia raccolta',
        device:'Mio dispositivo',
        signIn:'Login',
        loginOut:'Logout'
    },
    fotter:{
        privacy:'Politica sulla privacy',
        terms:'Termini di servizio',
        reserved:'Tutti i diritti riservati: tokit2020'
    },
    home:{
        featuredrecipes:'Menù',
        more:'Conosci di più',
        collections:'Collezione popolare',
        delicacy:'Specialità',
        collection:'Raccolta quotidiana',
        latestRecipes:'Ultima ricetta',
        cookbook:'Ricerca',
        search:'Ricerchi',
        link:'Connessione rapida',
        getTheDelicacies: 'Ottieni l’alimento delizioso',
        gainSomeInspirations: "Ottieni un po’ d’ispirazione"
    },
    recipesearch:{
        noresult:'Nessun risultato trovato nella ricerca selezionata.',
        viewMore:'Visualizza di più',
        collection:'Collezione aggiornata con successo!',
        recipe:'Ricetta eliminata dalla raccolta!',
        noMore: 'Dati provvisori non disponibili!',
        newest: 'Più recente',
        shortest: 'Tempo totale, minimo',
        recipes: 'Ricette',
        collections: 'Collezioni',
        tags: 'Tag',  
        products: 'Prodotto',
        category: 'Categoria',
        showResults: 'Mostra i risultati',
        Filters: 'Filtri', 
    },
    specialTopicList:{
        explore:'Esplori l’ultima collezione nostra',
        viewAll: 'Consulti tutti',
        Recipes:'ricette'
    },
    login:{
        logs:'Accedi al Suo conto',
        email:'Email',
        psd:'Password',
        by:'Tramite il login, Lei accetta:',
        useragreement:'Accordo d’utente',
        and:'e',
        login:'Login',
        or:'o',
        createaccount:'Crei il conto',
        forgotpassword:'Ha dimenticato il password',
        reserved:'©2021，Chunmi Technology (Hong Kong) Limited. Tutti i diritti riservati.',
        agree:'Accetto',
        privacy:'Leggi ed accetti la ns. Politica sulla privacy.',
        enterpass:'Inserisci l’email Suo per ricevere le istruzioni su come resettare la password.',
        exist:'Conto non esiste.',
        later:'C’è un problema, riprovi dopo.',
        reset:'Resetti',
        return:'o ritorni a',
        denglu:'Login',
        resetpsd:'Resetti la pwd',
        should:'Potresti ricevere un collegamento entro alcuni minuti. Apri il collegamento per resettare la password.',
        backIn:'Ritorni e accedi',
        setpad:'Imposti la pwd',
        minimum:'Deve essere almeno di 8 caratteri, compresi almeno una maiuscola, una minuscola ed una cifra',
        confirm:'Accerti',
        confirmpsd:'Confermi la pwd',
        passwordMismatch:'Incongruenza tra due pwd',
        incorrect :'Formato di pwd  scorretto',
        valids:'Offri la valida pwd.',
        setName:'Imposti il Suo nome',
        tip:'Appellativo deve essere univoco, in cinese o inglese, senza caratteri speciali',
        wrong:'C’è un problema, riprovi dopo.',
        usName:'Nome già occupato.',
        save:'Salvi',
        validmail:'Offri l’email valido',
        validpassword:'Offri la valida pwd.',
        validname:'Offri la valida nome',
        loginReminder:'Suggerimento login',
        format:'Adattati al formato richiesto'
    },
    recipeDetails:{
        difficulty:'Difficoltà',
        serves:'Servizio',
        preparationtime:'Tempo di preparazione',
        totaltime:'Tempo totale',
        ingredients:'Ingrediente',
        preparation:'Preparazione',
        relatedrecipes:'Ricetta relativa',
        min:'Punti',
        reterecipe:'Valuti la ricetta',
        evaluationTips:'Da 1 stella (peggiore) a 5 stelle(ottimo),  che ne pensi della ricetta?',
        angry:'Arrabbiato',
        disappointed:'Deluso',
        average:'Così così',
        satisfied:'Soddisfatto',
        vsatisfied:'Soddisfattissimo',
        clear:'Cancelli',
        saveRecipes: 'Salvare le ricette',
        removeRecipes: 'Cancellare le ricette',
        supportedProducts: 'Prodotti compatibili',
        tags: 'Tag',
        tips:'Suggerimenti',
        edit: 'Modificare',
        Duration:'Durata',
        Temperature:'Temperatura',
        Speed:'Velocità',
        corotation:'',
        reversal:'-',
        minutes:'minuti',
        easy:'Facile',
        Medium:'Medio',
        Difficult:'Difficile',
        title:'Dettagli della ricetta',
        positive:'Senso orario',
        reverse:'Senso antiorario',
        needHour:'ore',
        needMin:'minuti',
    },
    account:{
        hi:'Ciao,',
        profile:'Mei dati anagrafici',
        collection:'Mia raccolta',
        device:'Mio dispositivo',
        editprofile:'Modifichi il file di configurazione',
        firstname:'Nome',
        lastname:'Cognome',
        gender:'Sesso',
        birthday:'Data di nascita',
        country:'Paese/regione',
        email:'email',
        phonenum:'Tel',
        bundle:'Associ il Suo dispositivo TOKIT per godere del migliore servizio',
        addvice:'Aggiungi il dispositivo',
        bingdevice:'Associ il dispositivo',
        product:'Prodotto',
        blank:'Prodotto è richiesto',
        deviceNo:'Nr. dispositivo',
        modelBlank:'Modello è richiesto',
        submit:'Consegni',
        guide:'Guida per consultazione del nr. dispositivo',
        content:'Apri il menù, fare il singolo clic sull’impostazione per saltare alla pagina, poi fare il singolo clic su “riepilogo” per saltare alla pagina, in modo da consultare il dispositivo il cui modello non è visualizzato. Conosci di più',
        fullTxt:'Completo processo d’associazione',
        adddevice:'Aggiungi il mio dispositivo',
        choose:'Scegli il Suo prodotto.',
        modelNum:'Modello',
        personal:'Info personale',
        male:'Maschile',
        female:'Femminile',
        country:'Paese o regione',
        year:'Anno',
        month:'Mese',
        day:'Giorno',
        complete:"Completi l'associazione del Suo dispositivo.",
        completed:'Finisci',
        noDevice:'Nessun pop-up nel mio dispositivo',
        tryAlign:'Riprovi',
        removeCollection:'Elimini la collezione',
        doreally:'Confermi di eliminare la collezione?',
        firstRecipe:'Salvi la prima ricetta Sua!',
        theSave:'Clicchi per salvare',
        icon:'Icona sulla ricetta, per salvataggio e futuro uso.',
        findRecipe:'Ricerchi la ricetta',
        successfully:'Associazione riuscita!',
        removedevice:'Dispositivo eliminato.',
        bindingFailed:'Associazione fallita',
        removeTOKIT: 'Rimuovi TOKIT',
        removeTips: 'Vuoi davvero disattivare il tuo TOKIT?',
        cancel: 'CANCELLA',
        remove: 'TOGLIERE',
		loadTip:"Scarica l'APP per associare i dispositivi"
    },
    aboutus:{
        seek:'Cosa ricerchiamo',
        helping:'Facilitare la cottura',
        journey:'Adesso la cottura può essere un viaggio rilassante e piacevole.',
        providing:'Offrire la soluzione culinaria intelligente',
        continuing:'Proseguire la ricerca e lo sviluppo dell’esperienza della cucina più intelligente.',
        database:'Banca dati culinaria basata su Cloud',
        building:'Costruire un menù più estensivo e immaginario per l’utente.',
        history:'Storico',
        previous:'Indietro',
        next:'Avanti',
        ourJourney:'Il ns. viaggio comincia nel maggio',
        established:"Essendo fondato nel maggio 2013, TOKIT Technology ha avviato Spark e cominciato a dedicarsi allo sviluppo, l’applicazione della rete delle cose e gli elettrodomestici Cloud.",
        ourfirst:'Primo prodotto lanciato da noi',
        intend:"Con la finalità di ridefinire gli utensili da cucina in base ai requisiti tecnologici per i prodotti elettronici, abbiamo cominciato innanzitutto l'evoluzione e la riforma del fornello per riso. ",
        awards:'Premi da noi ottenuti fin dal 2020',
        since:'A partire dalla nascita, siamo stati riconosciuti da tante organizzazioni e ottenuto 48 premi dagli istituti come IF, Red Dot, G-Mark, IDEA ecc.',
        launched:'Lancio dei prototipi di TOKIT',
        developing:'Nell’intero anno 2020, abbiamo sviluppato i prototipi di TOKIT, rispettivamente T0, T1 e T2',
        Kickstarter:'$1142227 in occasione dell’evento estivo Kickstartor',
        reached:"Con il lancio formale del nostro TOKIT Omni Cook a livello globale, abbiamo ottenuto 1.566 fautori su kickerstarter e realizzato un giro d'affari di USD 1.142.227.",
        website:'Sito web e Cooknjoy nel prossimo settembre',
        distribution:'Nel settembre 2021, abbiamo lanciato il sito web ufficiale globale e la piattaforma Cooknjoy per prepararci alla distribuzione globale di TOKIT.',
        Soon:'Joyami sta arrivando',
        cooker:'Alla fine del 2021, abbiamo presentato il nostro sottomarchio Joyami di TOKIT. Nel seguente c’è l’anteprima del nostro nuovo fornello per riso a cartiglio Joyami.',
        define:'Definire l’atteggiamento culinario con la temperatura',
        creating:'Creare la tecnologia attendibile per arricchire la vita',
        staffed:'Composto da 300 elite provenienti dai magnati tecnologici come Apple, IBM, Philips: il team D conta un peso di 65%, Abbiamo finora oltre 800 brevetti e siamo muniti delle tecnologie genetiche e manifatturiere avanzate d’Internet'
    }
}