//通过文件下载url拿到对应的blob对象
const getBlob = (url) => {
  return new Promise((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = () => {
      if (xhr.status === 200) {
        resolve(xhr.response);
      }
    };

    xhr.send();
  });
};

//下载文件 js模拟点击a标签进行下载
const saveAs = (blob, filename) => {
  var link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);
  link.download = filename;
  link.click();
};

// 获取文件链接中的文件名
const getFileName = (url) => {
  const regex = /\/([^/?#]+)(\?|$)/ // 从最后一个斜杠后提取非斜杠、问号或井号的字符，直到问号或字符串结束
  const match = url.match(regex);
  return match ? match[1] : null
};

export const customDownloadDirective = {
  inserted: function (el, binding) {
    // 禁止右键
    el.addEventListener("contextmenu", function (e) {
      // const isPrevent = binding.value.isPrevent
      // if(!binding.value.fileUrl) return

      e.preventDefault();
      const watermarkedURL = binding.value.fileUrl || binding.value.customUrl;

      const realName = getFileName(watermarkedURL);
      const completeName = realName.indexOf('.') !== -1 ? realName : realName + '.png'
      getBlob(watermarkedURL).then((blob) => {
        saveAs(blob, completeName);
      });
    });

    // 阻止拖动
    el.addEventListener("dragstart", function (e) {
      e.preventDefault();
    });
  },
};

// 阻止右键
export const preventContextmenu = {
  inserted: function (el) {
    el.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  },
};
