export default {
    /**
     * @description token在Cookie中存储的天数，默认1天
     */
    cookieExpires: 1,
    cooknjoyUrl:'https://cooknjoy.tokit.com/', // 台湾地区  tokitglobal
    euUrl:'http://eu.cooknjoy.tokitglobal.com', // 欧洲地区
    ruUrl:'http://ru.cooknjoy.tokitglobal.com', // 俄罗斯地区

}
