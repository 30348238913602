import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'babel-polyfill'
import 'element-ui/lib/theme-chalk/index.css'
import 'bootstrap/dist/css/bootstrap.css';
import jquery from 'jquery'
import 'bootstrap';
import {library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import Lang from './components/Lang.vue'
import i18n  from '../src/lang/index'
import merge from 'webpack-merge';
import comJS from '../src/assets/js/public';
import gaPulgin from './libs/vGA'
import configInfo from './config/index'
import { customDownloadDirective } from '@/directive'

// TODO 上线隐藏此处
// import Vconsole from 'vconsole'
// const vConsole = new Vconsole();
// Vue.use(vConsole);

Vue.use(gaPulgin)
Vue.prototype.$merge = merge
Vue.prototype.$ = jquery
Vue.prototype.$comJS = comJS


import md5 from "js-md5"; //密码登陆时 对密码进行加密
Vue.prototype.$md5 = md5;

library.add(fas, far, fab)

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('Lang', Lang)

import globalVariable from './api/global_variable'

Vue.prototype.GLOBAL = globalVariable

Vue.prototype.$CooknjoyUrl = configInfo.cooknjoyUrl

Vue.config.productionTip = false;



Vue.use(ElementUI, {size: 'small'});
//全局配置自定义指令获取焦点 v-focus
Vue.directive('focus',{
    inserted(el){
        el.focus()
    }
})

Vue.directive('no-right-click', customDownloadDirective)

//全局路由配置


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
