import Vue from 'vue'
import Router from 'vue-router'
import routes from './routers'
import store from '@/store'
import NProgress from 'nprogress'
import {getToken, setToken} from '@/libs/util'
import {Message} from 'element-ui'

Vue.use(Router)
 
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function push (to) {
  return VueRouterReplace.call(this, to).catch(err => err)
}
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new Router({
    routes,
    // base: '/apps/', // TODO 如果是发布到海外app中访问就加上这一行，如果是发布到食谱主站的地址就隐藏这一行
    mode: 'history', //default: hash ,history
    strict: process.env.NODE_ENV !== 'production',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

// 页面跟踪
// router.afterEach((to, from, next) => {
//   const gaID = window.localStorage.getItem('gaID')
//   console.log(to,'totototototo')
//   window.gtag('config', gaID, {
//       page_title: to.name || '其他页面',
//       page_path: to.name, // path
//       page_location: window.location.href
//   })
// })



// const LOGIN_PAGE_PATH = "/login";
 //路由开始之前的操作
//  router.beforeEach((to, from, next) => {
//      NProgress.done().start()
//      const token = getToken();
//      if (!token && to.path !== LOGIN_PAGE_PATH) {
//          // 未登录且要跳转的页面不是登录页
//          next({
//              path: LOGIN_PAGE_PATH// 跳转到登录页
//          })
//      } else if (token && to.path === LOGIN_PAGE_PATH) {
//          next({path: '/'})
//      } 
//      else {
//          store.dispatch('getSysAdminInfo').then((res) => {
//              if (res.code !== 200) {
//                  Message.error('登录已失效')
//                  setToken('')
//                  next({path: LOGIN_PAGE_PATH})
//              } else {
//                  next()
//              }
//          })
//      }
//      NProgress.done();
//  })
//路由完成之后的操作
// router.afterEach(() => {
//     NProgress.done()
// })
export default router
